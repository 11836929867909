
import { computed, defineComponent, ref, ComputedRef } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { useStore } from "vuex";
// import $ from 'jquery';
import { useRoute, useRouter } from "vue-router";

import PolicyDocuments from "@/components/partials/policy/ViewPolicyDocuments.vue";
import CustomerService from "@/core/services/car/CustomerService";
// import ViewAllDocuments from "@/components/partials/policy/ViewAllLeadDocuments.vue";
import ViewTempDocuments from "@/components/partials/policy/ViewTempDocuments.vue";
interface IQuickCustomer {
  name: string;
  email: string;
  phone_number: string;
  lead_source: string;
  salesAgents: string;
}

export default defineComponent({
  name: "policy-document-modal",
  components: {
    PolicyDocuments,
    // ViewAllDocuments,
    ViewTempDocuments,
  },
  props: {
    showCategory: String,
    isCancellation: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTempDocs: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup() {
    const store = useStore();

    const policyDocuments = computed(() => {
      return store.getters.getTempPolicyDocs;
    });
    const documents = computed(() => {
      // return store.state.PolicyModule.policyDocuments;
      return store.getters.getPolicyDocs;
    });
    const cancellationDocs = computed(() => {
      return store.getters.getPolicyCancellationDocuments;
    });
    const policyTempDocuments = computed(() => {
      return store.getters.getTempPolicyDocs;
    });

    const allDocuments = computed(() => {
      return store.getters.getAllPolicyDocuments;
      // 
      // if (documents.value && policyTempDocuments.value) {
      //   var result = [...documents.value, ...policyTempDocuments.value];
      //   return result.sort((a,b) => {
      //     return a?.document_type?.sort_order - b?.document_type?.sort_order;
      //   });
      // }else{
      //   return [];
      // }
    });

    return {
      policyDocuments,
      cancellationDocs,
      documents,
      allDocuments,
      policyTempDocuments,
    };
  },
});
