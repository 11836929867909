
import { defineComponent, computed, ref } from "vue";
import PolicyService from "@/core/services/car/PolicyService";

import { useStore } from "vuex";
import router from "@/router";
import MasterService from '@/core/services/car/MasterService';
import { string } from "yup";


export default defineComponent({
  name: "policyt-refund-cancel-action",
  components: {},
  props: {
    policy: Object,
    action: String,
    showcancelAction: Boolean,
    workFlow: String,
    refundId: {
      type:String,
      required:false,
      default:''
    },
    isShowRefund: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCancellation: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPolicyRefund: {
      type: Boolean,
      required: false,
      default: false
    },
    showCancelled: {
      type: Boolean,
      required: false,
      default: false
    },
    invoice_id: {
      type: Number,
      required: false,
      default: 0
    },
    cancelStatus: {
      type: Number,
      required: false
    },
    isApproveViewCancellation: {
      type: Boolean,
      required: false,
      default: false
    },
    isViewRefund: {
      type: Boolean,
      required: false,
      default: false
    },
    isqca: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowViewPolicy: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props, context) {
    const store = useStore();

    const userPermissions = computed(() => {
      return store.getters.getUserPermissions;
    });

    const current = computed(() => {
      return store.getters.currentUser;
    });

    const openRefundModal = ref(false);

    const openPolicy = (value, category = 1,refundId='') => {
      PolicyService.getPolicyDetails({
          policy_id: value,
          refund_req_id:refundId
        }).then(() => {
          PolicyService.setRefundModal(true);
          MasterService.getReasonnReason();
          PolicyService.setRefundCategory(category);
          PolicyService.setRefundInvoiceId(props.invoice_id);
          MasterService.getPayToMasters({refund: true});
        })
    }

    const viewPolicyDocument = (policy) => {
      const cert = policy.find(x => x.type === 1)
        if(cert) window.open(cert.file_path,'_blank');
    }

    const showAction = (data) => {
      const user = store.getters.currentUser;
      return data.includes(user.role_id);
    }

    const showViewPolicyAllDocuments = computed(() => {
      const nameRoute = router.currentRoute.value.name;
      let data:any = [];
      if(nameRoute === 'policy-cancellation-approval') {
        data = [1, 9, 6];
      } else if(nameRoute === 'policy-cancellation-request') {
        data = [1, 9, 4];
      } else {
        data = [1, 9, 13, 2, 4, 6];
      }

      const user = store.getters.currentUser;
      return data.includes(user.role_id);
    })

    const showViewCancellationDocuments = computed(() => {
      const nameRoute = router.currentRoute.value.name;
      let data:any = [];

      if(nameRoute === 'policy-cancellation-approval') {
        data = [1, 9, 6, 15];
      } else {
        data = [1, 9, 13, 2, 4, 6, 14, 15];
      }

      const user = store.getters.currentUser;
      return data.includes(user.role_id);
    })

    const openCancelPolicy = (value) => {
        context.emit('is_view_cancellation_details', false);
        if(props.cancelStatus == 7) {
          context.emit('is_view_cancellation_details', true);
        }
        context.emit('send_policy_id',value, 'cancel', true);
    }

    const openDocuments = (policy_id, lead_id) => {
      PolicyService.getAllViewDocuments({policy_id, lead_id})
    }

    const toPolicyPage = (policy) => {
      PolicyService.setPolicyView(false);
      const route = router.resolve({ name: 'upload-policy-documents', params: { policyId: policy.id } })
      window.location.href = route.href
    }
    
    const viewPolicy = (id) => {
      PolicyService.setPolicyView(true);
      const link = router.resolve({name: 'upload-policy-documents', params: {policyId: id}});
      window.location.href = link.href
    }

    return {
      toPolicyPage,
      openDocuments,
      openCancelPolicy,
      showViewCancellationDocuments,
      showViewPolicyAllDocuments,
      showAction,
      viewPolicyDocument,
      openRefundModal,
      current,
      openPolicy,
      // uploadFile,
      // formData
      userPermissions,
      router,
      viewPolicy,
    };
  },
});
