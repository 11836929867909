
import { computed, defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { useStore } from "vuex";
// import $ from 'jquery';
import { useRoute, useRouter } from "vue-router";
import ViewTempDocuments from "@/components/partials/policy/ViewTempDocuments.vue";
// import PolicyDocuments from "@/components/partials/policy/ViewPolicyDocuments.vue";
import CustomerService from "@/core/services/car/CustomerService";

interface IQuickCustomer {
  name: string;
  email: string;
  phone_number: string;
  lead_source: string;
  salesAgents: string;
}

export default defineComponent({
  name: "temp-document-modal",
  components: {
    ViewTempDocuments,
  },
  props: {
    policyId: [Number, String],
    showCategory: String,
    tempDocs: Array,
  },
  setup() {
    const store = useStore();
    const policyDocuments = computed(() => {
      return store.getters.getPolicyDocs;
    });
    const cancellationDocs = computed(() => {
      return store.getters.getPolicyCancellationDocuments;
    });

    const masterDocTypes = computed(() => {
      return store.getters.getTempPolicyDocs;
    });

    const loading = computed(() => {
      return store.getters.getLoadingTempDocument;
    });
    return { policyDocuments, cancellationDocs, masterDocTypes, loading };
  },
});
