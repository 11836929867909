
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import modalTable from '@/components/partials/Invoices/modalTable.vue';
import PolicyService from "@/core/services/car/PolicyService";

interface DownloadDocBodyParam{
    policy: Array<any>;
    temp:  Array<any>;
}

export default defineComponent({
  name: "view-all-document-modal",
  components: {
    modalTable
  },

  setup() {
    let downloadDocBodyParam = {} as DownloadDocBodyParam;

    const headers = ref([
      {
        label: 'Preview',
        prop: 'file_path',
        width: '300',
        align: 'center'
      },
      {
        label: 'Document Type',
        prop: 'title',
        width: '250',
        align: 'center'
      },
      {
        label: 'File Name',
        prop: 'filename',
        width: '250',
        align: 'center'
      },
      {
        label: 'Created at',
        prop: 'created_at',
        width: '180',
        align: 'center'
      },
    ])
    
    const store = useStore();

    const data = computed(() => {
      return store.getters.getDataViewAllDocuments;
    });

    const loading = computed(() => {
      return store.getters.getLoadingViewAllDocuments;
    });

    const open = computed(() => {
      return store.getters.getOpenViewAllDocuments;
    });
    

    const closeModal = () => {
      PolicyService.resetViewAllModal();
    }

    const downloadZip = (policyDocs) => {
      let policyDocArray = Array<any>();
      let tempPolicyDocArray = Array<any>();
      policyDocs.forEach((policyDoc) =>{
         if (policyDoc.is_temp == 0  && policyDoc?.document_type?.document_type == 'verification'){
          policyDocArray.push(policyDoc.id);
         }
          if (policyDoc.is_temp == 1 && policyDoc?.document_type?.document_type == 'verification'){
          tempPolicyDocArray.push(policyDoc.id);
         }
      });
      downloadDocBodyParam.policy = policyDocArray;
    downloadDocBodyParam.temp = tempPolicyDocArray;
     PolicyService.downloadZip(downloadDocBodyParam);
    };

    return {
      downloadZip,
      closeModal,
      headers,
      loading,
      open,
      data,
      
    };
  },
});
